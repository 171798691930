
@mixin grid($cols: none, $rows: none, $gap: normal normal) {
    display: grid;
    grid-template-columns: $cols;
    grid-template-rows: $rows;
    gap: $gap;
}

@mixin section($cols: 1fr,) {
    
}


@mixin no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin horizontal-scrolling {
    @include grid(16px, minmax(150px, 1fr), $gutter/2);
    grid-auto-flow: column;
    grid-auto-columns: calc(50% - $gutter * 2);
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    @include no-scrollbar;
    &::before,
    &::after {
        content: '';
        width: 16px;
    }
}
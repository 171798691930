@import './variables';
@import './mixins';
@import './components';

.app {
    width: 100%;
    height: 100%;
    @include grid(1fr, 50px 1fr);
    align-content: start;
    overflow-y: scroll;
    overflow-x: none;
    background: map-get($color, "white");
    img, svg, figure, > .header * { user-select: none }
    > .header {
        @include grid(16px min-content 1fr 16px);
        align-items: center;
        z-index: 1;
        > :first-child { grid-column: 2 / 3; justify-self: start }
        > :last-child { grid-column: 3 / 4; justify-self: end }
        > .header-logo > figure {
            width: 184px;
            height: 28px;
            cursor: pointer;
            background: url(https://www.pavator.md/assets/img/logo.svg) left/cover no-repeat;
            transition: .7s ease-in-out;
        }
        > .header-nav {
            display: grid;
            overflow: hidden;
            margin-left: $gutter;
            > .header-nav__list {
                @include grid(10px, auto, $gutter);
                grid-auto-flow: column;
                grid-auto-columns: auto;
                overflow-x: scroll;
                overflow-y: hidden;
                scroll-snap-type: x proximity;
                @include no-scrollbar;
                &::before { content: ''; width: 0 }
                > .header-nav__item {
                    place-items: center;
                    > .header-nav__link {
                        place-content: center;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                }
            }
        }
        &.header--scrolled { box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px; }
    }
    > .main {
        @include grid(16px 1fr 16px, auto, ($gutter * 3) 0);
        overflow: auto;
        align-content: start;
        > .section {
            grid-column: 2 / -2;
            @include grid(1fr, minmax(50px, auto) 1fr, $gutter);
            &.section--full { grid-column: 1 / -1 }
            &.section--no-title { grid-template-rows: 1fr }
            > .section__title {
                @include grid(1fr, 1fr auto);
                place-items: center;
                > h1,
                > h2 {
                    color: map-get($color, "black");
                    font-weight: 500;
                    font-size: 24px;
                }
                > h2 {
                    text-transform: uppercase;
                    display: inline-flex;
                    align-items: center;
                    &::before,
                    &::after {
                        content: '';
                        width: 20px;
                        height: 2px;
                        background: map-get($color, "blue");
                    }
                    &::before { margin-right: .25em }
                    &::after { margin-left: .25em }
                }
                &.section__title--no-subtitle { grid-template-rows: 1fr }
            }
            > .section__content {
                @include grid(1fr, auto, $gutter);
                place-content: center;
                font-size: 16px;
                > figure > img { width: 100%; height: auto }
                p { margin-bottom: .2em }
                .section__content--list > li {
                    display: inline-flex;
                    > svg { fill: map-get($color, "blue"); margin-right: .15em }
                }
                .section__content--horizontal-list {
                    @include grid($gap: $gutter * 3);
                    grid-auto-flow: column;
                    grid-auto-columns: min-content;
                    overflow-x: scroll;
                    scroll-snap-type: x mandatory;
                    padding-top: $gutter;
                    padding-bottom: $gutter * 3;
                }
            }
            > .section__title.section__title--spacing,
            > .section__content.section__content--spacing {
                grid-template-columns: 16px 1fr 16px;
                > * { grid-column: 2 / -2 }
            }
            &.hero {
                grid-template-rows: 1fr auto;
                grid-template-areas: 
                    "hero-content"
                    "hero-title";
                .section__title {
                    grid-area: hero-title;
                    @include grid(16px 1fr 16px, auto, $gutter 0);
                    > * { grid-column: 2 / 3 }
                    h1,
                    h2 {
                        text-transform: unset;
                        &::before, &::after { content: none }
                    }
                    h1 {
                        color: map-get($color, "black");
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 36px;
                    }
                    h2 {
                        color: map-get($color, "grey");
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .section__content { grid-area: hero-content }
            }
            &.clients {
                background: map-get($color, "marine-blue");
                > .section__content {
                    gap: 0;
                    padding: $gutter 0;
                    > .clients__list {
                        @include grid(1fr 1fr, 1fr, $gutter);
                        place-items: center;
                        > li > img {
                            place-self: center;
                            width: 100px;
                            height: auto;
                            filter: grayscale(1);
                            &:hover { filter: none }
                        }
                    }
                }
            }
            &.about > .section__content {
                > :first-child { grid-column: 1 / -1 }
                h3, p { line-height: 1.5 }
            }
            &.counts > .section__content {
                > .counts-list {
                    @include grid(1fr, 1fr, $gutter);
                    place-content: center;
                    > li {
                        @include grid(auto 1fr, auto auto, $gutter / 2);
                        grid-template-areas: 
                            "counts-icon counts-number"
                            "counts-icon counts-text";
                        svg {
                            grid-area: counts-icon;
                            fill: map-get($color, "blue");
                        }
                        span {
                            grid-area: counts-number;
                            color: map-get($color, "black");
                            font-weight: 700;
                            font-size: 36px;
                            line-height: 30px;
                            display: block;
                        }
                        p {
                            grid-area: counts-text;
                        }
                    }
                }
                &.section__content--cols-2-3 > .counts-list {
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto auto;
                }
            }
            &.services,
            &.equipment {
                > .section__content { 
                    overflow: hidden;
                    grid-template-columns: 16px 1fr 16px;
                    > * { grid-column: 2 / -2 }
                }
            }
            &.services .card > figure { background: url(bg.jpg) center/cover }
            &.equipment .card > figure {
                background: map-get($color, "black");
                img {
                    top: unset;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                }
            }
            &.contact > .section__content {
                grid-template-areas: 
                    "contact-info"
                    "contact-map"
                    "contact-address"
                    "contact-form";
                > .contact__info,
                > .contact__address,
                > .contact__form { @include grid($cols: 1fr, $gap: $gutter) }
                > .contact__info {
                    grid-area: contact-info;
                    align-items: start;
                }
                > .contact__address {
                    grid-area: contact-address;
                    font-style: normal;
                    @include grid($cols: 1fr, $gap: $gutter);
                    grid-template-areas:
                    "addr-address"
                    "addr-email"
                    "addr-tel"
                    "social-icons";
                    .contact__address--address,
                    .contact__address--email,
                    .contact__address--tel {
                        @include grid(min-content 1fr, $gap: $gutter/2);
                        align-items: center;
                        color: map-get($color, "grey");
                        & a {
                            color: inherit;
                        }
                        & > svg {
                            fill: map-get($color, "blue");
                        }
                        .phones-list a {
                            display: block;
                            line-height: 2;
                        }
                    }
                    .contact__address--address {
                        grid-area: addr-address;
                    }
                    .contact__address--email {
                        grid-area: addr-email;
                    }
                    .contact__address--tel {
                        grid-area: addr-tel;
                    }
                    .contact__social-media-icons {
                        grid-area: social-icons;
                        @include grid(max-content, min-content, $gutter);
                        grid-auto-flow: column;
                        grid-auto-columns: min-content;
                        align-content: center;
                        svg { fill: map-get($color, "grey") }
                    }
                }
                > .contact__map {
                    grid-area: contact-map;
                    width: 100%;
                    min-height: 100px;
                    height: 200px;
                }
                > .contact__form {
                    grid-area: contact-form;
                    @include grid($cols: 1fr, $gap: $gutter);
                    fieldset {
                        input,
                        textarea {
                            width: 100%;
                            padding: 10px 15px;
                            font-family: inherit;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 1.5;
                            color: map-get($color, "black");
                            border-radius: 0;
                            background-color: map-get($color, "white");
                            background-clip: padding-box;
                            border: 1px solid map-get($color, "light-grey");
                            -webkit-appearance: none;
                               -moz-appearance: none;
                                    appearance: none;
                            outline: none;
                            transition: .2s ease-in-out;
                            &:focus {
                                border-color: map-get($color, "grey");
                            }
                        }
                        textarea {
                            min-height: calc(1.5em + .75rem + 2px);
                            overflow: auto;
                            resize: none;
                        }
                        .contact__form-error {
                            color: map-get($color, "red");
                        }
                    }
                    .button {
                        justify-self: right;
                    }
                }
            }
        }
        > .footer {
            grid-column: 1 / -1;
            @include grid($cols: 16px 1fr 16px, $gap: 10px 0);
            background: map-get($color, "white-grey");
            padding: 16px 0;
            > * {
                grid-column: 2 / -2;
                place-self: center;
                text-align: center;
                * { display: inline-block }
                .pw-icon {
                    transition: .3s;
                    fill: map-get($color, "grey");
                }
            }
        }
    }
    > .mobile-nav {
        position: fixed;
        top: 0;
        left: 0;
        @include grid(1fr, minmax(50px, auto) 1fr);
        width: calc(100% - 50px);
        height: 100%;
        background: rgba(255,255,255,.95);
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .23);
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(0);
        transition: transform .3s ease-in, left .3s ease-in;
        z-index: 2000;
        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            & {
                background: rgba(255,255,255,.8);
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
            }
        }
        &:not(.mobile-nav--active) {
            transform: translateX(-100%);
            box-shadow: unset;
        }
        > .mobile-nav__header {
            @include grid(1fr);
            place-content: center;
            place-items: center;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
    
        }
        > .mobile-nav__list {
            @include grid(
                minmax(16px, env(safe-area-inset-left)) 
                1fr 
                minmax(16px, env(safe-area-inset-right)));
            grid-auto-rows: 48px;
            > .mobile-nav__item {
                grid-column: 2 / -2;
                @include grid(1fr);
                align-items: center;
                &:not(:last-child):not(:nth-last-child(2)) {
                    border-bottom: 1px solid map-get($color, "light-grey");
                }
                > .mobile-nav__link {
                    display: block;
                    line-height: 100%;
                    font-weight: 600;
                    font-size: 18px;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
                    &.mobile-nav__link--offer {
                        padding: 12px;
                        text-align: center;
                        border: none;
                        border-radius: 24px;
                        box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }
}

@import './keyframes';
@import './media';
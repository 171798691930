.locked {
    overflow: hidden;
    pointer-events: none;
    touch-action: none;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 9px;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    > * {
        width: 20px;
        height: 3px;
        border-radius: 1.5px;
        background-color: map-get($color, "black");
        pointer-events: none;
        touch-action: none;
        transition: .2s .1s ease-in-out;
    }
    &.hamburger--open > * {
        background-color: map-get($color, "dark-grey");
        &:nth-child(1) { transform: translateY(3px) rotate(45deg); }
        &:nth-child(2) { transform: translateY(-3px) rotate(-45deg); }
    }
}

.switcher {
    padding: 2px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: fit-content;
    background-color: map-get($color, "dark-grey");
    border-radius: 6px;
    border: 0;
    -webkit-tap-highlight-color: transparent;
    > .switcher__input {
        position: absolute;
        clip: rect(0,0,0,0);
        &:checked+.switcher__label {
            color: map-get($color, "dark-grey");
            font-weight: 400;
            transition: .2s;
        }
        &:nth-of-type(1):checked~.switcher__zipper{
            transform: translateX(0);
        }
        &:nth-of-type(2):checked~.switcher__zipper{
            transform: translateX(calc(100% - 4px));
        }
    }
    > .switcher__label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: map-get($color, "white-grey");
        cursor: pointer;
        z-index: 1;
    }
    > .switcher__zipper {
        position: absolute;
        width: 50%;
        height: 24px;
        background-color: map-get($color, "white-grey");
        border-radius: 5px;
        transition: .3s ease-in-out;
    }
}

.button {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    outline: none;
    transition: .5s;
    &.button__outline {
        background: map-get($color, "white");
        border: 2px solid map-get($color, "black");
        color: map-get($color, "black");
        &.button__outline--blue {
            border-color: map-get($color, "blue");
            color: map-get($color, "blue");
            &:focus {
                color: map-get($color, "white");
                background: map-get($color, "blue");
            }
        }
    }
    &.button__fill {
        background: map-get($color, "black");
        border: 2px solid map-get($color, "black");
        color: map-get($color, "white");
        &:focus,
        &:disabled {
            background: map-get($color, "grey");
            border-color: map-get($color, "grey");
        }
        &.button__fill--blue {
            background: map-get($color, "blue");
            border-color: map-get($color, "blue");
            color: map-get($color, "white");
            &:focus {
                background: map-get($color, "light-blue");
                border-color: map-get($color, "light-blue");
            }
        }
        &.button__fill--red {
            background: map-get($color, "red");
            border-color: map-get($color, "red");
            color: map-get($color, "white");
            &:focus {
                background: map-get($color, "light-red");
                border-color: map-get($color, "light-red");
            }
        }
    }
    &.button__transition--slow {
        transition: 3s;
    }
    &.button--full {
        width: 100%;
    }
}


.card {
    @include grid(220px, 160px auto 1fr auto); 
    grid-template-areas:
        "card-figure"
        "card-title"
        "card-body"
        "card-footer";
    font-size: 16px;
    text-align: center;
    background: #fff;
    border-radius: 20px;
    scroll-snap-align: center;
    box-shadow: -1px 15px 30px -12px rgba(0, 0, 0, .8);
    > figure {
        grid-area: card-figure;
        position: relative;
        background: lightgray;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 20px);
            height: auto;
        }
    }
    > h2, > p, > footer { padding: 5px }
    > h2 { grid-area: card-title; font-size: 1.2em }
    > p { grid-area: card-body }
    > footer { 
        grid-area: card-footer; 
        @include grid(1fr);
        align-self: end; 
        font-size: .8em;
        color: lightgray;
        background: dimgray;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        &.cols-2 {
            grid-template-columns: 1fr 1fr;
            padding: 0;
            > div { padding: 5px }
            > div:first-child { border-right: 1px solid darkgrey }
        }
        > div * { display: inline-block }
    }
}


.error-shake {
    -webkit-animation: error-shake 0.4s 1 linear;
       -moz-animation: error-shake 0.4s 1 linear;
         -o-animation: error-shake 0.4s 1 linear;
            animation: error-shake 0.4s 1 linear;
}


@media (hover: hover) and (pointer: fine) {
    .button {
        &.button__outline {
            &:hover {
                background: map-get($color, "white-grey");
            }
            &.button__outline--blue:hover {
                color: map-get($color, "white");
                background: map-get($color, "blue");
            }
        }
        &.button__fill {
            &:hover {
                background: map-get($color, "grey");
                border-color: map-get($color, "grey");
            }
            &.button__fill--blue:hover {
                background: map-get($color, "light-blue");
                border-color: map-get($color, "light-blue");
            }
            &.button__fill--red:hover {
                background: map-get($color, "light-red");
                border-color: map-get($color, "light-red");
            }
        }
    }
    .pw-icon:hover { fill: map-get($color, "black") }
}
@keyframes up-down {
    0% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}  

@keyframes error-shake {
    0% { -webkit-transform: translate(30px); transform: translate(30px); }
    20% { -webkit-transform: translate(-30px); transform: translate(-30px); }
    40% { -webkit-transform: translate(15px); transform: translate(15px); }
    60% { -webkit-transform: translate(-15px); transform: translate(-15px); }
    80% { -webkit-transform: translate(8px); transform: translate(8px); }
    100% { -webkit-transform: translate(0px); transform: translate(0px); }
}

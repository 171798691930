@import './mediascreen';

@include landscape() {
    .app {
        > .header > .hamburger, > .mobile-nav { display: none }
        > .main {
            > .section {
                &.about > .section__content { grid-template-columns: 1fr 1fr }
                &.clients > .section__content > .clients__list { grid-template-columns: repeat(5, 1fr) }
                &.contact > .section__content {
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: auto auto;
                    row-gap: 0;
                    grid-template-areas: 
                    "contact-info contact-form contact-map"
                    "contact-address contact-form contact-map";
                    > .contact__info,
                    > .contact__address { gap: 0 }
                    > .contact__map { height: 100% }
                }
            }
            > .footer {
                grid-template-columns: 16px 1fr 1fr 16px;
                > * { text-align: unset }
                > :first-child { grid-column: 2/3; justify-self: start }
                > :last-child { grid-column: 3/4; justify-self: end }
            }
        }
    }
}

@include device(iphonex iphonexsmax iphone12 iphone12promax iphone12mini) {
    .app > .main > .footer { grid-template-columns: $safeL 1fr $safeR; padding-bottom: $safeB }
}
@include device(iphonex iphonexsmax iphone12 iphone12promax iphone12mini, landscape) {
    .app {
        > .header { grid-template-columns: $safeL min-content 1fr $safeR }
        > .main,
        > .main > .section > .section__title.section__title--spacing,
        > .main > .section > .section__content.section__content--spacing,
        > .main > .section.hero > .section__title
        { grid-template-columns: $safeL 1fr $safeR }
        > .main > .footer { grid-template-columns: $safeL 1fr 1fr $safeR }
    }
}

@include device(mobile, portrait) {
    .app > .main > .section > .section__content .section__content--horizontal-list {
        @include grid(1fr, 1fr, $gutter * 3);
        grid-auto-flow: column;
        grid-auto-columns: min-content;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        padding-top: $gutter;
        padding-bottom: $gutter * 3;
        scroll-padding: 50%;
        @include no-scrollbar;
        &::before,
        &::after {
            content: '';
            width: 1px;
            scroll-snap-align: unset;
        }
    }
}

@include device(mobile, landscape) {
    .app {
        > .main {
            > .section {
                &.contact > .section__content {
                    grid-template-columns: 1fr;
                    grid-template-areas: 
                    "contact-info"
                    "contact-map"
                    "contact-address"
                    "contact-form";
                    gap: $gutter;
                    > .contact__info,
                    > .contact__address { gap: $gutter }
                    > .contact__map { height: 200px }
                }
            }
        }
    }
}

@include device(tablet laptop desktop) {
    .app {
        > .main {
            > .section {
                &.hero {
                    margin-top: $gutter/2;
                    grid-template-rows: none;
                    grid-template-areas: ". hero-title hero-content .";
                    > .section__title {
                        grid-template-columns: 1;
                        h1 { font-size: 48px; line-height: 56px }
                        h2 { font-size: 24px }
                        .button { justify-self: start }
                    }
                    > .section__content {
                        img {
                            animation: up-down 2s ease-in-out infinite alternate-reverse both;
                        }
                    }
                }
                &.counts > .section__content { 
                    grid-template-columns: 2fr 3fr;  
                    > .counts-list {
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                    }
                }
                &.equipment,
                &.services {
                    grid-template-columns: 1fr;
                    > .section__content {
                        overflow: unset;
                        > .section__content--horizontal-list {
                            grid-template-columns: unset;
                            grid-template-rows: unset;
                            grid-auto-flow: column;
                            grid-auto-rows: auto;
                            overflow-x: none;
                            scroll-snap-type: unset;
                            padding-top: $gutter;
                            padding-bottom: $gutter * 2;
                            scroll-padding: unset;
                            
                            &::before,
                            &::after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include device(tablet) {
    .app {
        > .header { grid-template-columns: 1fr 184px 584px 1fr }
        > .main,
        > .main > .section > .section__title.section__title--spacing,
        > .main > .section > .section__content.section__content--spacing,
        > .main > .section.equipment > .section__content,
        > .main > .section.services > .section__content
        { grid-template-columns: 1fr 768px 1fr }
        > .main > .section.hero,
        > .main > .footer { grid-template-columns: 1fr 384px 384px 1fr }
        > .main > .section.contact > .section__content {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "contact-info contact-info"
            "contact-map  contact-map"
            "contact-address contact-form";
            gap: $gutter;
            > .contact__map { height: 400px }
        }
    }
}

@include device(laptop) {
    .app {
        > .header { grid-template-columns: 1fr 184px 840px 1fr }
        > .main,
        > .main > .section > .section__title.section__title--spacing,
        > .main > .section > .section__content.section__content--spacing,
        > .main > .section.equipment > .section__content,
        > .main > .section.services > .section__content
        { grid-template-columns: 1fr 1024px 1fr }
        > .main > .section.hero,
        > .main > .footer { grid-template-columns: 1fr 512px 512px 1fr }
    }
}

@include device(desktop) {
    .app {
        > .header { grid-template-columns: 1fr 184px 1016px 1fr }
        > .main,
        > .main > .section > .section__title.section__title--spacing,
        > .main > .section > .section__content.section__content--spacing,
        > .main > .section.equipment > .section__content,
        > .main > .section.services > .section__content
        { grid-template-columns: 1fr 1200px 1fr }
        > .main > .section.hero,
        > .main > .footer { grid-template-columns: 1fr 600px 600px 1fr }
    }
}
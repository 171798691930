*,
*::before,
*::after {
-webkit-box-sizing: border-box;
        box-sizing: border-box
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    outline: 0
}

article,
aside,
code,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
svg {
    display: block
}

html,
body {
    height: 100%;
}

html {
    font: 17px/1.5 "SF Pro Text", "SF Pro Icons", -apple-system, BlinkMacSystemFont, Open Sans, Roboto, Helvetica Neue, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
    -webkit-font-kerning: normal;
            font-kerning: normal;
    -webkit-font-feature-settings: kern "1", "liga" 0, "clig" 0;
        -ms-font-feature-settings: kern "1", "liga" 0, "clig" 0;
         -o-font-feature-settings: kern "1", "liga" 0, "clig" 0;
            font-feature-settings: kern "1", "liga" 0, "clig" 0;
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
    position: relative;
}

body {
    background: #fff;
    color: #4b4b4b;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", sans-serif;
}

a,
a:link,
a:visited,
a:hover,
a.active,
a:active,
a:focus {
    outline: 0;
    text-decoration: none;
    background-color: transparent
}

sub,
sup {
    font-size: .75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}

code {
    padding: 6px;
    background-color: #ECF1F7;
    border-radius: 6px
}

svg {
    pointer-events: none;
}

@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-iteration-count: 1 !important;
                animation-iteration-count: 1 !important;
        -webkit-animation-duration: 0.01ms !important;
                animation-duration: 0.01ms !important;
        -webkit-transition-duration: 0.01ms !important;
             -o-transition-duration: 0.01ms !important;
                transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

#pavator {
    width: 100%;
    height: 100%;
}

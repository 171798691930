@use "sass:map";

$color: (
    "blue": #3498DA,
    "light-blue": #58AAE1,
    "marine-blue": #F3F9FD,
    "white-blue": #E1F0FA,

    "red": #c12f2f,
    "light-red": #de7878,

    "grey": #4B4B4B,
    "dark-grey": #3c3c3c,
    "light-grey": #a2a2a2,
    "white-grey": #ECF1F7,

    "black": #141414,
    "white": #FFFFFF,
);
$gutter: 12px;
$safeA: env(safe-area-inset);
$safeT: env(safe-area-inset-top);
$safeL: env(safe-area-inset-left);
$safeR: env(safe-area-inset-right);
$safeB: env(safe-area-inset-bottom);

.cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255,.8);
  z-index: 9999;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

.ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--content-blue);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: ellipsis1 0.6s infinite;
          animation: ellipsis1 0.6s infinite;
}

.ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: ellipsis2 0.6s infinite;
          animation: ellipsis2 0.6s infinite;
}

.ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: ellipsis2 0.6s infinite;
          animation: ellipsis2 0.6s infinite;
}

.ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: ellipsis3 0.6s infinite;
          animation: ellipsis3 0.6s infinite;
}

@-webkit-keyframes ellipsis1 {
  0% {
      -webkit-transform: scale(0);
              transform: scale(0);
  }
  100% {
      -webkit-transform: scale(1);
              transform: scale(1);
  }
}

@keyframes ellipsis1 {
  0% {
      -webkit-transform: scale(0);
              transform: scale(0);
  }
  100% {
      -webkit-transform: scale(1);
              transform: scale(1);
  }
}

@-webkit-keyframes ellipsis3 {
  0% {
      -webkit-transform: scale(1);
              transform: scale(1);
  }
  100% {
      -webkit-transform: scale(0);
              transform: scale(0);
  }
}

@keyframes ellipsis3 {
  0% {
      -webkit-transform: scale(1);
              transform: scale(1);
  }
  100% {
      -webkit-transform: scale(0);
              transform: scale(0);
  }
}

@-webkit-keyframes ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@keyframes ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}